import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import border from "../assets/border.png";
import restaurant from "../assets/IMG-20241011-WA0029.jpg";
import { useTranslation } from 'react-i18next'; // Import useTranslation

const Restaurant = () => {
  const { t } = useTranslation(); // Hook to access translation function
  const { ref: headingRef, inView: headingInView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const textVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const imgVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
  };

  return (
    <div className="w-full py-10 bg-white" id="restaurant">
      <div className="flex items-center justify-center flex-col w-full text-6xl">
        <motion.h1
          className="playfair text-center"
          ref={headingRef}
          initial="hidden"
          animate={headingInView ? "visible" : "hidden"}
          variants={textVariants}
        >
          <span className="text-orange-700">Restaurant</span> {/* Use translation for Restaurant title */}
        </motion.h1>

        <motion.img
          src={border}
          className="w-[200px] mt-5"
          alt="border"
          initial="hidden"
          animate={headingInView ? "visible" : "hidden"}
          variants={imgVariants}
        />
      </div>

      <div className="flex flex-wrap justify-center items-center mt-10">
        {/* 50% width image of restaurant */}
        <motion.img
          src={restaurant}
          className="w-full md:w-[40%] rounded-lg h-auto object-cover"
          alt="Our Restaurant"
          initial="hidden"
          animate={headingInView ? "visible" : "hidden"}
          variants={imgVariants}
        />

        {/* 50% width information about the restaurant */}
        <motion.div
          className="w-full md:w-1/2 p-5 text-center md:text-left"
          initial="hidden"
          animate={headingInView ? "visible" : "hidden"}
          variants={textVariants}
        >
          <h2 className="text-4xl font-semibold mb-5">{t('restaurant_title')}</h2> {/* Use translation for title */}
          <p className="text-lg leading-7 mb-5">
            {t('restaurant_description2')} {/* Use translation for description */}
          </p>
          <p className="text-lg leading-7">
            {t('restaurant_thaali')} {/* Use translation for thaali description */}
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default Restaurant;
