// src/components/LanguageSwitcher.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language; // Get the current language

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="language-switcher flex justify-center">
      {/* Render the inactive language button */}
      {currentLanguage === 'en' ? (
        <button
          onClick={() => changeLanguage('hi')}
          className="p-1 px-3 m-2 border rounded text-sm md:text-base"
        >
          हिंदी
        </button>
      ) : (
        <button
          onClick={() => changeLanguage('en')}
          className="p-1 px-3 m-2 border rounded text-sm md:text-base"
        >
          English
        </button>
      )}
    </div>
  );
};

export default LanguageSwitcher;
