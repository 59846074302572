import React from 'react'
import Navbar from '../components/Navbar'
import img from "../assets/IMG-20241011-WA0017.jpg"
import Header from '../components/Header'
import Showcase from '../components/Showcase'
import Explore from '../components/Explore'
import Gallery from '../components/Gallery'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import Amneties from '../components/Amneties'
import GalleryAd from '../components/GalleryAd'
import Restaurant from '../components/Restaurant'

const Home = () => {
  return (
    <div 
      className="h-[200vh] w-screen bg-contain" 
      
    >
        <Navbar/>
        <Header/>
        <Showcase/>
        <Explore/>
        {/* <Gallery/> */}
        <Amneties/>
        <Restaurant/>
        <GalleryAd/>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default Home
