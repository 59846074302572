import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa"; // Import WhatsApp icon
import Showcase from "./components/Showcase"; // Update with your actual import
import Explore from "./components/Explore"; // Update with your actual import
import Contact from "./components/Contact"; // Update with your actual import
import RoomInfo from "./pages/RoomInfo";
import Home from "./pages/Home";
import Gallery from "./components/Gallery";
import './i18n';

const App = () => {
  const openWhatsApp = () => {
    window.open('https://wa.me/6397492882', '_blank'); // Replace with your WhatsApp number
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/rooms" element={<Explore />} />
        <Route path="/rooms/:roomType" element={<RoomInfo />} />
      </Routes>

      {/* WhatsApp Button */}
      <button className="whatsapp-button" onClick={openWhatsApp}>
        <FaWhatsapp size={32} />
      </button>
    </Router>
  );
};

export default App;
