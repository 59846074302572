// Navbar.js
import React, { useState, useEffect } from "react";
import { FaHotel } from "react-icons/fa";
import { motion } from "framer-motion";
import { CiStar } from "react-icons/ci";
import LanguageSwitcher from '../components/LangaugeSwitcher'; // Corrected import
import { useTranslation } from 'react-i18next'; // Import useTranslation

const Navbar = () => {
  const { t } = useTranslation(); // Hook to access translation function
  const [isSticky, setIsSticky] = useState(false);
  const [showName, setShowName] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      setShowName(scrollPosition < 80);
      setIsSticky(scrollPosition > 80);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`top-0 w-full z-[2000] ${isSticky ? "fixed" : "absolute"}`}>
      {/* First Div: Hotel Name */}
      <motion.div
        className={`flex items-center flex-col p-6 w-full justify-center backdrop-blur-md ${showName ? "" : "invisible"}`}
        initial={{ opacity: 1 }}
        animate={{ opacity: showName ? 1 : 0 }}
        transition={{ duration: 0.3 }}
      >
        <div className="flex w-full items-center justify-center pb-6 gap-5 border-b border-gray-400">
          <h2 className="text-2xl playfair-display text-gray-300">Hotel</h2> {/* Translated hotel title */}
          <FaHotel className="text-4xl text-white" />
          <h2 className="text-2xl playfair-display text-gray-300">Goverdhan</h2> {/* Translated hotel title */}
          
        </div>

        {/* Hide this div on small screens */}
        <div className={`items-center justify-center hidden md:flex abel gap-16 p-6 border-b border-gray-400 w-full text-white text-xl uppercase backdrop-blur-sm ${isSticky ? "hidden sm:flex" : ""}`}>
          <a href="#home" className="text-lg font-semibold hover:text-blue-500 transition">{t('home')}</a>
          <a href="#rooms" className="text-lg font-semibold hover:text-blue-500 transition">{t('rooms')}</a>
          <a href="/gallery" className="text-lg font-semibold hover:text-blue-500 transition">{t('gallery')}</a>
          <a href="#contact" className="text-lg font-semibold hover:text-blue-500 transition">{t('contact')}</a>
        </div>
      </motion.div>

      {/* Second Div: Nav Links */}
      <motion.div
        className={`flex items-center bg-[#EA580C] justify-center abel gap-6 md:gap-16 p-6 border-b border-gray-400 w-full text-white text-xl uppercase backdrop-blur-sm fixed top-0`}
        initial={{ opacity: 0 }}
        animate={{ opacity: isSticky ? 1 : 0 }}
        transition={{ duration: 0.3 }}
      >
        <a href="#home" className="md:text-lg text-sm font-semibold hover:text-blue-500 transition">{t('home')}</a>
        <a href="#rooms" className="md:text-lg text-sm font-semibold hover:text-blue-500 transition">{t('rooms')}</a>
        <a href="/gallery" className="md:text-lg text-sm font-semibold hover:text-blue-500 transition">{t('gallery')}</a>
        <a href="#contact" className="md:text-lg text-sm font-semibold hover:text-blue-500 transition">{t('contact')}</a>

        {/* Language Switcher added to the Navbar */}
        <LanguageSwitcher />
      </motion.div>
    </div>
  );
};

export default Navbar;
