import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next'; // Import useTranslation

const GalleryAd = () => {
  const { t } = useTranslation(); // Hook to access translation function

  return (
    <motion.div
      className="w-full bg-gray-900 py-10 text-white flex justify-center items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="flex flex-col items-center text-center">
        <h2 className="text-4xl font-bold playfair mb-4">
          {t('gallery_title')} {/* Use translation for gallery title */}
        </h2>
        <p className="text-lg mb-6 text-gray-400">
          {t('gallery_description')} {/* Use translation for gallery description */}
        </p>
        <Link to="/gallery">
          <motion.button
            className="px-8 py-3 bg-orange-600 text-white text-xl font-semibold rounded-lg shadow-lg hover:bg-orange-700 transition-all"
            whileHover={{ scale: 1.05 }}
          >
            {t('gallery_button')} {/* Use translation for gallery button */}
          </motion.button>
        </Link>
      </div>
    </motion.div>
  );
};

export default GalleryAd;
