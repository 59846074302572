// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          welcome: "Welcome",
          hotel: "Hotel",
          govind_residency: "Govind Residency",
          your_second_home: "Your second home",
          booking: "Book Now",
          rooms: "Rooms",
          home: "Home",
          gallery: "Gallery",
          contact: "Contact",
          deluxe_room: "Deluxe Room",
          deluxe_description:
            "A beautifully designed room with elegant interiors and a comfortable bed, perfect for a relaxing stay.",
          super_deluxe_room: "Super Deluxe Room",
          super_deluxe_description:
            "Enjoy extra space and luxury in our Super Deluxe room, featuring premium bedding and a spacious seating area.",
          luxury_suite: "Luxury Suite",
          luxury_suite_description:
            "Indulge in our premium Luxury Suite, offering separate living areas, panoramic views, and world-class amenities.",
          explore_our: "Explore Our",
          staffs: "Staff",
          clients: "Clients",
          luxury_rooms: "Luxury Rooms and Suites",
          showcase_description:
            "Indulge in the finest accommodations with our selection of luxury rooms and suites, designed to provide a blend of sophistication and comfort. Each room is meticulously curated with modern amenities, ensuring a perfect balance between relaxation and style.",
          rooftop: "Rooftop",
          rooftop_description:
            "Our beautiful rooftop terrace offers stunning panoramic views of the city skyline. A perfect spot for evening relaxation and private gatherings under the stars.",
          restaurant: "Restaurant",
          restaurant_description:
            "Dine in our exquisite restaurant, offering a wide variety of gourmet dishes prepared by top chefs. Experience local and international cuisine in a cozy and elegant ambiance.",
          party_halls: "Party Halls",
          party_halls_description:
            "Spacious party halls available for weddings, anniversaries, corporate events, and social gatherings. Equipped with modern facilities to make your events unforgettable.",
            restaurant_title: "Discover Global Flavors",
            restaurant_description2: "At our restaurant, we take pride in offering a unique dining experience that brings together the best flavors from around the world. From traditional Indian delicacies to international cuisines, we ensure that every dish is prepared with the finest ingredients and utmost care.",
            restaurant_thaali: "Our signature offering is the Special International Thaali, a culinary journey around the globe. This platter features an exquisite selection of dishes inspired by various cuisines, providing a truly international dining experience. Whether you are in the mood for rich Indian spices, Mediterranean zest, or Asian fusion, our thaali has something for everyone.",
            gallery_title: "Discover Our Stunning Gallery",
            gallery_description: "Explore breathtaking views and luxurious amenities through our hotel gallery.",
            gallery_button: "View Gallery",
            explore_room: "Explore Room",
        },
      },
      hi: {
        translation: {
          welcome: "स्वागत है",
          hotel: "होटल",
          govind_residency: "गोविंद रेजिडेंसी",
          your_second_home: "आपका दूसरा घर",
          booking: "अब बुक करें",
          rooms: "कमरे",
          home: "मुख्य पृष्ठ",
          gallery: "गेलरी",
          contact: "संपर्क करें",
          deluxe_room: "डीलक्स रूम",
          deluxe_description:
            "एक खूबसूरती से डिजाइन किया गया कमरा जिसमें भव्य इंटीरियर्स और एक आरामदायक बिस्तर है, जो एक आरामदायक ठहरने के लिए बिल्कुल सही है।",
          super_deluxe_room: "सुपर डीलक्स रूम",
          super_deluxe_description:
            "हमारे सुपर डीलक्स कमरे में अतिरिक्त स्थान और लक्जरी का आनंद लें, जिसमें प्रीमियम बिस्तर और एक विशाल बैठक क्षेत्र है।",
          luxury_suite: "लक्जरी सुइट",
          luxury_suite_description:
            "हमारे प्रीमियम लक्जरी सुइट में शामिल हों, जो अलग लिविंग क्षेत्रों, पैनोरमिक दृश्य और विश्व स्तरीय सुविधाएँ प्रदान करता है।",
          explore_our: "हमारा अन्वेषण करें",
          staffs: "स्टाफ",
          clients: "ग्राहक",
          luxury_rooms: "लक्ज़री कमरे और सुइट",
          showcase_description:
            "हमारे लक्ज़री कमरों और सुइट्स के चयन के साथ बेहतरीन आवास में शामिल हों, जो sophistication और आराम का एक मिश्रण प्रदान करने के लिए डिज़ाइन किए गए हैं। प्रत्येक कमरा आधुनिक सुविधाओं के साथ सावधानीपूर्वक तैयार किया गया है, जो विश्राम और शैली के बीच एक सही संतुलन सुनिश्चित करता है।",
          rooftop: "रूफटॉप",
          rooftop_description:
            "हमारी सुंदर रूफटॉप टैरेस शहर के स्काईलाइन का शानदार दृश्य पेश करता है। शाम की विश्राम और सितारों के नीचे निजी समारोहों के लिए एकदम सही स्थान।",
          restaurant: "रेस्तरां",
          restaurant_description:
            "हमारे उत्कृष्ट रेस्तरां में भोजन करें, जो शीर्ष रसोइयों द्वारा तैयार किए गए विभिन्न प्रकार के गॉरमेट व्यंजन पेश करता है। एक आरामदायक और सुरुचिपूर्ण माहौल में स्थानीय और अंतर्राष्ट्रीय व्यंजनों का अनुभव करें।",
          party_halls: "पार्टी हॉल",
          party_halls_description:
            "शादियों, सालगिरहों, कॉर्पोरेट इवेंट्स और सामाजिक समारोहों के लिए उपलब्ध विशाल पार्टी हॉल। आपके इवेंट्स को अविस्मरणीय बनाने के लिए आधुनिक सुविधाओं से लैस।",
            restaurant_title: "वैश्विक स्वादों की खोज करें",
            restaurant_description2: "हमारे रेस्तरां में, हम दुनिया भर के सर्वश्रेष्ठ स्वादों को एक साथ लाने वाले अनोखे भोजन अनुभव की पेशकश करने पर गर्व करते हैं। पारंपरिक भारतीय व्यंजनों से लेकर अंतर्राष्ट्रीय व्यंजनों तक, हम सुनिश्चित करते हैं कि हर व्यंजन सर्वोत्तम सामग्री और अत्यधिक देखभाल के साथ तैयार किया गया है।",
            restaurant_thaali: "हमारी विशेष पेशकश विशेष अंतर्राष्ट्रीय थाली है, जो दुनिया भर की एक पाक यात्रा है। यह प्लेटर विभिन्न व्यंजनों से प्रेरित व्यंजनों का एक उत्कृष्ट चयन प्रस्तुत करता है, जो वास्तव में अंतर्राष्ट्रीय भोजन अनुभव प्रदान करता है। चाहे आप समृद्ध भारतीय मसालों, भूमध्यसागरीय ज़ेस्ट या एशियाई फ्यूजन के मूड में हों, हमारी थाली में सभी के लिए कुछ न कुछ है।",
            gallery_title: "हमारी शानदार गैलरी की खोज करें",
            gallery_description: "हमारे होटल की गैलरी के माध्यम से आश्चर्यजनक दृश्य और शानदार सुविधाओं का अन्वेषण करें।",
            gallery_button: "गैलरी देखें",
            explore_room: "कमरे खोजें",
          
        },
      },
    },
    lng: "en", // Default language
    fallbackLng: "en", // Use English if translation for the selected language is not available
    interpolation: {
      escapeValue: false, // React already escapes values to prevent XSS
    },
  });

export default i18n;
