import React from 'react';
import { motion } from 'framer-motion';
import img from "../assets/595320622.jpg";
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation(); // Hook to access translation function

  const textVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1.2, delay: 0.2 } },
  };

  const buttonVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.6, delay: 0.6 } },
  };

  return (
    <div
      className="flex items-center justify-center text-white h-screen w-full bg-cover bg-center"
      style={{
        backgroundImage: `url(${img})`,
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
      }}
      id='home'
    >
      <div className='flex items-center flex-col w-full h-full justify-center bg-black bg-opacity-40 p-6 rounded-lg'>
        <motion.h3 
          className='text-3xl sm:text-4xl md:text-5xl font-bold text-gray-400'
          initial="hidden"
          animate="visible"
          variants={textVariants}
        >
          {t('hotel')} {/* Translated hotel title */}
        </motion.h3>
        <motion.h1 
          className='text-5xl sm:text-6xl text-center md:text-7xl font-bold playfair my-4'
          initial="hidden"
          animate="visible"
          variants={textVariants}
          transition={{ delay: 0.8 }}
        >
          {t('govind_residency')} {/* Translated hotel name */}
        </motion.h1>
        <motion.h3 
          className='text-2xl sm:text-3xl md:text-4xl font-bold playfair-display mb-4 text-gray-400'
          initial="hidden"
          animate="visible"
          variants={textVariants}
          transition={{ delay: 1.2 }}
        >
          {t('your_second_home')} {/* Translated description */}
        </motion.h3>
        <motion.div 
          className='flex gap-4 flex-wrap' // Added flex-wrap for responsiveness
          initial="hidden"
          animate="visible"
          variants={buttonVariants}
        >
          <motion.button 
            className='p-3 border rounded-xl hover:bg-white hover:text-black transition-all duration-350 text-sm sm:text-base'
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => window.open('https://www.booking.com/hotel/in/govind-residency-govardhan1.html', '_blank')}
          >
            {t('booking')} {/* Translated button text */}
          </motion.button>

        </motion.div>
      </div>
    </div>
  );
}

export default Header;
