// Explore.js
import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import border from "../assets/border.png";
import deluxe from "../assets/deluxe.jpg";
import superdeluxe from "../assets/superdeluxe.jpg";
import suite from "../assets/suite1.jpg";
import { GiForkKnifeSpoon } from "react-icons/gi";
import { LiaSwimmingPoolSolid } from "react-icons/lia";
import { FaPeopleRoof } from "react-icons/fa6";
import { useTranslation } from 'react-i18next'; // Import useTranslation

const Explore = () => {
  const { t } = useTranslation(); // Hook to access translation function
  const { ref: headingRef, inView: headingInView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const textVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const imgVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
  };

  return (
    <div className="w-full py-10 bg-slate-100" id="rooms">
      <div className="flex items-center justify-center flex-col w-full text-6xl">
        <motion.h1
          className="playfair text-center"
          ref={headingRef}
          initial="hidden"
          animate={headingInView ? "visible" : "hidden"}
          variants={textVariants}
        >
          Explore Our <span className="text-orange-700">Rooms</span>
        </motion.h1>

        <motion.img
          src={border}
          className="w-[200px] mt-5"
          alt="border"
          initial="hidden"
          animate={headingInView ? "visible" : "hidden"}
          variants={imgVariants}
        />
      </div>

      <div className="flex flex-wrap items-start justify-center gap-8 mt-6">
        {/* Deluxe Room */}
        <div className="w-full sm:w-[400px] cursor-pointer h-auto border rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300">
          <img
            src={deluxe}
            className="w-full h-[300px] object-cover"
            alt={t('deluxe_room')} // Use translation for alt text
          />
          <div className="p-6">
            <h3 className="text-2xl md:text-3xl font-semibold playfair mb-3">
              {t('deluxe_room')} {/* Translated room title */}
            </h3>
            <p className="text-gray-600 mb-4">
              {t('deluxe_description')} {/* Translated room description */}
            </p>
            <div className="flex items-center text-3xl my-5 text-gray-400">
              <GiForkKnifeSpoon />
              <LiaSwimmingPoolSolid />
              <FaPeopleRoof />
            </div>
            <Link to="/rooms/deluxe">
              <button className="px-6 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700 transition-all">
                {t('explore_room')} {/* You can add this translation if desired */}
              </button>
            </Link>
          </div>
        </div>

        {/* Super Deluxe Room */}
        <div className="w-full sm:w-[400px] cursor-pointer h-auto border rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300">
          <img
            src={superdeluxe}
            className="w-full h-[300px] object-cover"
            alt={t('super_deluxe_room')} // Use translation for alt text
          />
          <div className="p-6">
            <h3 className="text-2xl md:text-3xl font-semibold playfair mb-3">
              {t('super_deluxe_room')} {/* Translated room title */}
            </h3>
            <p className="text-gray-600 mb-4">
              {t('super_deluxe_description')} {/* Translated room description */}
            </p>
            <div className="flex items-center text-3xl my-5 text-gray-400">
              <GiForkKnifeSpoon />
              <LiaSwimmingPoolSolid />
              <FaPeopleRoof />
            </div>
            <Link to="/rooms/superdeluxe">
              <button className="px-6 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700 transition-all">
                {t('explore_room')} {/* You can add this translation if desired */}
              </button>
            </Link>
          </div>
        </div>

        {/* Luxury Suite */}
        <div className="w-full sm:w-[400px] cursor-pointer h-auto border rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300">
          <img
            src={suite}
            className="w-full h-[300px] object-cover"
            alt={t('luxury_suite')} // Use translation for alt text
          />
          <div className="p-6">
            <h3 className="text-2xl md:text-3xl font-semibold playfair mb-3">
              {t('luxury_suite')} {/* Translated room title */}
            </h3>
            <p className="text-gray-600 mb-4">
              {t('luxury_suite_description')} {/* Translated room description */}
            </p>
            <div className="flex items-center text-3xl my-5 text-gray-400">
              <GiForkKnifeSpoon />
              <LiaSwimmingPoolSolid />
              <FaPeopleRoof />
            </div>
            <Link to="/rooms/suite">
              <button className="px-6 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700 transition-all">
                {t('explore_room')} {/* You can add this translation if desired */}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Explore;
