import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import sh1 from "../assets/showcase.png";
import { FaHotel, FaUser, FaUserTie } from "react-icons/fa";
import { useTranslation } from 'react-i18next'; // Import useTranslation

const Showcase = () => {
  const { t } = useTranslation(); // Hook to access translation function
  const { ref: sectionRef, inView: sectionInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const { ref: imgRef, inView: imgInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const cardVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  };

  return (
    <div className='flex flex-col md:flex-row h-auto py-10'>
      <div className='flex-1 ml-0 md:ml-10 flex items-center flex-col text-center gap-8 justify-center'>
        <motion.h1 
          className='playfair text-4xl md:text-6xl'
          initial={{ opacity: 0, y: -50 }}
          animate={sectionInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8 }}
          ref={sectionRef}
        >
          <h3 className="text-orange-700">

          {t('luxury_rooms')} {/* Use translation for the title */}
          </h3>
        </motion.h1>
        <motion.p
          initial={{ opacity: 0 }}
          animate={sectionInView ? { opacity: 1 } : {}}
          transition={{ duration: 1.2 }}
          className='px-4 md:px-0'
        >
          {t('showcase_description')} {/* Use translation for the description */}
        </motion.p>
      </div>
      <div className='flex-1 flex items-center justify-center mt-9 md:mt-0'>
        <motion.img
          ref={imgRef}
          src={sh1}
          className='w-[90%] md:w-[80%] h-[600px] rounded-lg shadow-md shadow-black transform scale-90 transition-all duration-700 ease-in-out'
          alt="Showcase"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={imgInView ? { opacity: 1, scale: 1 } : {}}
        />
      </div>
    </div>
  );
};

export default Showcase;
