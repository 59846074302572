import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel"; // Import the carousel component
import { motion } from "framer-motion"; // Import framer-motion for animations
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel CSS
import { FaArrowLeft } from "react-icons/fa"; // Import back arrow icon
import suite from "../assets/suite1.jpg";
import suite2 from "../assets/suite2.jpg";
import suite3 from "../assets/suite3.jpg";
import deluxe from "../assets/deluxe.jpg";
import deluxe2 from "../assets/deluxe2.jpg";
import deluxe3 from "../assets/deluxe3.jpg";
import superdeluxe from "../assets/superdeluxe.jpg";
import superdeluxe2 from "../assets/sdeluxe2.jpg";
import superdeluxe3 from "../assets/sdeluxe3.jpg";

const RoomInfo = () => {
  const { roomType } = useParams();
  const navigate = useNavigate(); // Hook to navigate back

  const roomDetails = {
    deluxe: {
      title: "Deluxe Room",
      description:
        "A beautifully designed room with elegant interiors and a comfortable bed, perfect for a relaxing stay.",
      images: [deluxe, deluxe2, deluxe3],
    },
    superdeluxe: {
      title: "Super Deluxe Room",
      description:
        "Enjoy extra space and luxury in our Super Deluxe room, featuring premium bedding and a spacious seating area.",
      images: [superdeluxe, superdeluxe2, superdeluxe3],
    },
    suite: {
      title: "Luxury Suite",
      description:
        "Indulge in our premium Luxury Suite, offering separate living areas, panoramic views, and world-class amenities.",
      images: [suite, suite3, suite2],
    },
  };

  const room = roomDetails[roomType.replace("-", "")];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.8 } },
  };

  return (
    <motion.div
      className="flex flex-col items-center justify-center h-auto md:h-screen"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      {/* Navbar */}
      <motion.nav
        className="w-full flex items-center justify-between px-4 py-2 bg-orange-600 text-white fixed top-0 left-0"
        initial={{ y: -50 }}
        animate={{ y: 0, transition: { duration: 0.5 } }}
      >
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-white"
        >
          <FaArrowLeft className="mr-2" />
          Back
        </button>
        <h1 className="text-xl md:text-2xl font-bold text-center">
          Hotel Goverdhan Residency
        </h1>
        <div className="w-[24px]" /> {/* Placeholder for empty right side */}
      </motion.nav>

      {/* Room content */}
      {room ? (
        <motion.div
          className="flex flex-col md:flex-row items-center justify-center gap-7 w-full mt-20 px-5"
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          {/* Image Carousel on the left */}
          <motion.div
            className="w-full md:w-[40%] flex justify-center"
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1, transition: { duration: 0.8 } }}
          >
            <Carousel
              showThumbs={false}
              infiniteLoop
              autoPlay
              interval={3000}
              showArrows={true}
              className="rounded-lg shadow-lg w-full"
            >
              {room.images.map((image, index) => (
                <div key={index}>
                  <img
                    src={image}
                    alt={`${room.title} ${index + 1}`}
                    className="rounded-lg"
                  />
                </div>
              ))}
            </Carousel>
          </motion.div>

          {/* Room info on the right */}
          <motion.div
            className="w-full md:w-[40%] flex flex-col justify-center"
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1, transition: { duration: 0.8 } }}
          >
            <h2 className="text-3xl font-bold mb-4">{room.title}</h2>
            <p className="text-lg text-gray-600 mb-6">{room.description}</p>
            <motion.button
              className="px-6 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700 transition-all"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() =>
                window.open(
                  "https://www.booking.com/hotel/in/govind-residency-govardhan1.html",
                  "_blank"
                )
              }
            >
              Book Room
            </motion.button>
          </motion.div>
        </motion.div>
      ) : (
        <motion.p
          className="text-center text-xl mt-20"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.5 } }}
        >
          Room not found.
        </motion.p>
      )}
    </motion.div>
  );
};

export default RoomInfo;
