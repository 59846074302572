import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import border from "../assets/border.png";
import { FaUtensils, FaMountain } from "react-icons/fa";
import { MdPartyMode } from "react-icons/md";
import rooftop from "../assets/suite2.jpg";
import restaurant from "../assets/restaurant.jpg";
import halls from "../assets/hallw.jpg"; 
import { useTranslation } from 'react-i18next'; // Import useTranslation

const Amneties = () => {
  const { t } = useTranslation(); // Hook to access translation function
  const { ref: headingRef, inView: headingInView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const textVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const imgVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
  };

  return (
    <div className="w-full py-10 bg-gradient-to-b from-white to-slate-100" id="amenities">
      <div className="flex items-center justify-center flex-col text-6xl">
        {/* Heading */}
        <motion.h1
          className="playfair text-center"
          ref={headingRef}
          initial="hidden"
          animate={headingInView ? "visible" : "hidden"}
          variants={textVariants}
        >
          Hotel <span className="text-orange-700">{t('amenities')}</span>
        </motion.h1>

        {/* Decorative Border */}
        <motion.img
          src={border}
          className="w-[200px] mt-5"
          alt="border"
          initial="hidden"
          animate={headingInView ? "visible" : "hidden"}
          variants={imgVariants}
        />
      </div>

      <div className="flex flex-wrap justify-center items-center gap-8 mt-10 px-5 md:px-20">
        {/* Rooftop Section */}
        <motion.div
          className="flex flex-col cursor-default items-center md:flex-row bg-white rounded-lg shadow-lg overflow-hidden w-full md:w-[48%]"
          whileHover={{ scale: 1.05 }}
        >
          <img
            src={rooftop}
            className="w-full md:w-1/2 h-64 object-cover rounded-l-lg"
            alt="Rooftop"
          />
          <div className="px-6 py-4 md:py-0 w-full md:w-1/2">
            <h2 className="text-4xl font-bold mb-3 playfair text-orange-600">
              {t('rooftop')} {/* Use translation for Rooftop */}
            </h2>
            <p className="text-gray-600 mb-3">
              {t('rooftop_description')} {/* Use translation for Rooftop description */}
            </p>
            <FaMountain className="text-4xl text-orange-600" />
          </div>
        </motion.div>

        {/* Restaurant Section */}
        <motion.div
          className="flex flex-col cursor-default items-center md:flex-row bg-white rounded-lg shadow-lg overflow-hidden w-full md:w-[48%]"
          whileHover={{ scale: 1.05 }}
        >
          <img
            src={restaurant}
            className="w-full md:w-1/2 h-64 object-cover rounded-l-lg"
            alt="Restaurant"
          />
          <div className="px-6 py-4 md:py-0 w-full md:w-1/2">
            <h2 className="text-4xl font-bold mb-3 playfair text-orange-600">
              {t('restaurant')} {/* Use translation for Restaurant */}
            </h2>
            <p className="text-gray-600 mb-3">
              {t('restaurant_description')} {/* Use translation for Restaurant description */}
            </p>
            <FaUtensils className="text-4xl text-orange-600" />
          </div>
        </motion.div>

        {/* Party Halls Section */}
        <motion.div
          className="flex flex-col cursor-default items-center md:flex-row bg-white rounded-lg shadow-lg overflow-hidden w-full md:w-[48%] mt-8 md:mt-0"
          whileHover={{ scale: 1.05 }}
        >
          <img
            src={halls}
            className="w-full md:w-1/2 h-64 object-cover rounded-l-lg"
            alt="Party Halls"
          />
          <div className="px-6 py-4 md:py-0 w-full md:w-1/2">
            <h2 className="text-4xl font-bold mb-3 playfair text-orange-600">
              {t('party_halls')} {/* Use translation for Party Halls */}
            </h2>
            <p className="text-gray-600 mb-3">
              {t('party_halls_description')} {/* Use translation for Party Halls description */}
            </p>
            <MdPartyMode className="text-4xl text-orange-600" />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Amneties;
