import React, { useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft, FaTimes } from "react-icons/fa";
import border from "../assets/border.png";
import "react-loading-skeleton/dist/skeleton.css";

// Import your images here
import img1 from "../assets/suite2.jpg";
import img2 from "../assets/IMG-20241011-WA0029.jpg";
import img3 from "../assets/hall.jpg"; // Replace with actual paths of images
import img4 from "../assets/deluxe.jpg";
import img5 from "../assets/superdeluxe.jpg";
import img6 from "../assets/deluxe2.jpg";
import img7 from "../assets/deluxe3.jpg";
import img8 from "../assets/sdeluxe2.jpg";
import img9 from "../assets/sdeluxe3.jpg";
import img10 from "../assets/sh1.jpg";
import img11 from "../assets/sh2.jpg";
import img12 from "../assets/sh3.jpg";
import img13 from "../assets/sh4.jpg";
import img14 from "../assets/sh5.jpg";
import img15 from "../assets/showcase.png";
import img16 from "../assets/suite1.jpg";
import img17 from "../assets/suite2.jpg";
import img18 from "../assets/suite2.jpg";
import img19 from "../assets/suite3.jpg";
import img20 from "../assets/IMG-20241011-WA0017.jpg";
import img21 from "../assets/IMG-20241011-WA00178.jpg"; //17 178 29
import img22 from "../assets/restaurant.jpg";
import img23 from "../assets/hallw.jpg";
import img24 from "../assets/IMG-20241011-WA0008.jpg";
import img25 from "../assets/IMG-20241011-WA0009.jpg";
import img26 from "../assets/IMG-20241011-WA0010.jpg";
import img27 from "../assets/IMG-20241011-WA0011.jpg";
import img28 from "../assets/IMG-20241011-WA0012.jpg";
import img29 from "../assets/IMG-20241011-WA0014.jpg";
import img30 from "../assets/IMG-20241011-WA0015.jpg";
import img31 from "../assets/IMG-20241011-WA0017.jpg";
import img32 from "../assets/IMG-20241011-WA0018.jpg";
import img33 from "../assets/IMG-20241011-WA0020.jpg";
import img34 from "../assets/IMG-20241011-WA0021.jpg";
import img35 from "../assets/IMG-20241011-WA0022.jpg";
import img36 from "../assets/IMG-20241011-WA0023.jpg";
import img37 from "../assets/IMG-20241011-WA0024.jpg";
import img38 from "../assets/IMG-20241011-WA0025.jpg";
import img39 from "../assets/IMG-20241011-WA0026 (1).jpg";
import img40 from "../assets/IMG-20241011-WA0032.jpg";
import img41 from "../assets/IMG-20241011-WA0035.jpg";
import img42 from "../assets/IMG-20241011-WA0037.jpg";
import Skeleton from "react-loading-skeleton";

const Gallery = () => {
  const navigate = useNavigate(); // Hook to navigate back
  const [selectedImage, setSelectedImage] = useState(null); // State for the selected image
  const [isLoaded, setIsLoaded] = useState([]);
  const { ref: headingRef, inView: headingInView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const textVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const imgVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
  };

  // All images array
  const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img31,
    img32,
    img33,
    img34,
    img35,
    img36,
    img37,
    img38,
    img39,
    img40,
    img41,
    img42,
  ];
  const handleImageLoad = (index) => {
    setIsLoaded((prevLoaded) => {
      const newLoaded = [...prevLoaded];
      newLoaded[index] = true;
      return newLoaded;
    });
  };


  return (
    <div className="flex flex-col items-center w-full min-h-screen pt-10 bg-white pb-10">
      {/* Navbar */}
      <motion.nav
        className="w-full z-10 flex items-center justify-between px-4 py-2 bg-orange-600 text-white fixed top-0 left-0"
        initial={{ y: -50 }}
        animate={{ y: 0, transition: { duration: 0.5 } }}
      >
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-white"
        >
          <FaArrowLeft className="mr-2" />
          Back
        </button>
        <h1 className="text-xl md:text-2xl font-bold text-center">
          Hotel Goverdhan Residency
        </h1>
        <div className="w-[24px]" /> {/* Placeholder for empty right side */}
      </motion.nav>

      {/* Heading */}
      <div className="flex items-center w-full pt-20 justify-center flex-col text-6xl">
        <motion.h1
          className="playfair"
          ref={headingRef}
          initial="hidden"
          animate={headingInView ? "visible" : "hidden"}
          variants={textVariants}
        >
          <span className="text-orange-700">Gallery</span>
        </motion.h1>
        <motion.img
          src={border}
          className="w-[200px] mt-5"
          alt=""
          initial="hidden"
          animate={headingInView ? "visible" : "hidden"}
          variants={imgVariants}
        />
      </div>

      {/* Image Gallery */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-10 px-4 w-full">
        {images.map((image, index) => (
          <motion.div
            key={index}
            className="overflow-hidden cursor-pointer"
            onClick={() => setSelectedImage(image)} // Open image in lightbox
            whileHover={{ scale: 1.05 }}
          >
            {!isLoaded[index] && <Skeleton height={300} />} {/* Skeleton */}
            <img
              src={image}
              alt={`Gallery image ${index + 1}`}
              className={`w-full h-full object-cover rounded-lg ${isLoaded[index] ? '' : 'hidden'}`}
              onLoad={() => handleImageLoad(index)} // Mark image as loaded
            />
          </motion.div>
        ))}
      </div>

      {/* Lightbox for selected image */}
      {selectedImage && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex justify-center items-center z-50">
          <div className="relative">
            <img
              src={selectedImage}
              alt="Selected"
              className="w-[90vw] h-[90vh] object-contain rounded-lg"
            />
            <button
              className="absolute top-5 right-5 text-white text-3xl"
              onClick={() => setSelectedImage(null)} // Close lightbox
            >
              <FaTimes />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;