import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import border from "../assets/border.png";
import { FaPhoneAlt } from "react-icons/fa";

const Contact = () => {
  const { ref: headingRef, inView: headingInView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.3, // Trigger when 30% of the element is visible
  });
  
  const textVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const imgVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
  };

  return (
    <div className="flex flex-col items-center w-full py-10" id="contact">
      <div className="flex items-center w-full justify-center flex-col text-6xl">
        <motion.h1
          className="playfair"
          ref={headingRef}
          initial="hidden"
          animate={headingInView ? "visible" : "hidden"}
          variants={textVariants}
        >
          <span className="text-orange-700">Contact Us</span>
        </motion.h1>
        <motion.img
          src={border}
          className="w-[200px] mt-5"
          alt=""
          initial="hidden"
          animate={headingInView ? "visible" : "hidden"}
          variants={imgVariants}
        />
      </div>
      
      {/* Google Maps Iframe */}
      <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3539.0925966162827!2d77.46721147524593!3d27.497495576303436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39730f0067cd6417%3A0x6013bc98e07effde!2sHotel%20Govind%20Residency!5e0!3m2!1sen!2sin!4v1728900715655!5m2!1sen!2sin" 
        className="rounded-lg shadow-md shadow-black mt-9 w-full h-[400px] md:h-[800px]" 
        style={{ border: 0 }} 
        allowFullScreen="" 
        loading="lazy" 
        referrerPolicy="no-referrer-when-downgrade">
      </iframe>

      {/* Phone Number Section */}
      <div className="flex items-center mt-5 text-lg">
        <FaPhoneAlt className="text-3xl text-orange-700 mr-2" />
        <span className="text-gray-600">+91 63974 92882</span>
      </div>
    </div>
  );
};

export default Contact;
