import React from 'react'

const Footer = () => {
  return (
    <div className='w-full bg-black text-white text-lg p-10 flex items-center justify-center'>
        &copy; Govind Residency
    </div>
  )
}

export default Footer